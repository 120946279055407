import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import { BCMCharacter } from '../../../modules/bcm/common/components/bcm-character';

const BCMGuidesFarmGear: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="bcm">
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Best teams for farm gear</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/blackclover/categories/cat_gear.png"
            alt="Best teams for farm gear"
          />
        </div>
        <div className="page-details">
          <h1>Best teams for farm gear</h1>
          <h2>List of Best teams for farm gear in Black Clover Mobile.</h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <p>
          The teams featured in the guide were tested by us and we were able to
          safely farm Stage 15 on auto with them, but they can be used for any
          lower stages too and only gear is stopping you from progressing.
          Basically, you farm the highest stage you can at the moment, gear your
          team with what drops and then move higher.
        </p>
        <p>
          The placement of the team members matters - try to place your
          Defenders/Healers (so tankier characters) on the edges, because
          somehow those positions are attacked more often.
        </p>
        <SectionHeader title="Red Dungeon" />
        <p>
          We suggest to focus on the Red Dungeon first as it's the easiest
          dungeon to create a team for (especially if you follow our reroll
          guide) and it also drops 3 highly universal advanced sets that can be
          used on everyone - ATK, DEF and Speed.
        </p>
        <h5>Main team</h5>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="charmy-pappitson" enablePopover />
          <BCMCharacter mode="icon" slug="lotus-whomalt" enablePopover />
          <BCMCharacter mode="icon" slug="solid-silva" enablePopover />
          <BCMCharacter mode="icon" slug="sol-marron" enablePopover />
        </div>
        <p>Here's how you can make the team better:</p>
        <ul>
          <li>
            Charmy can be replaced by William to make the runs smoother and
            faster, BUT this won't work when you're starting - you really need
            the heals from Charmy, so we suggest sticking with her in the early
            game and only switching to William once you farm some LR gear to
            make the runs faster,
          </li>
          <li>
            Gauche can be used instead of Solid Silva - it depends who you pull
            and how many dupes you will get. If you're looking for an upgrade,
            then Blue Yami is the first one, followed by Fana - she's the best
            choice for the team, but she's not available on release,
          </li>
          <li>
            Sol can be replaced by Mars - this is a straight upgrade that will
            make your runs faster and safer,
          </li>
        </ul>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="william-vangeance" enablePopover />
          <BCMCharacter mode="icon" slug="mars" enablePopover />
          <BCMCharacter
            mode="icon"
            slug="yami-sukehiro-clover-academy"
            enablePopover
          />
          <BCMCharacter mode="icon" slug="fana" enablePopover />
        </div>
        <h5>Skill setup</h5>
        <p>
          Make sure that Charmy only uses her Skill 2 - you want her to
          constantly heal the tank and not waste turns on her Special Move or
          Combined Attack. The rest of the characters can use default skill
          setup.
        </p>
        <SectionHeader title="Blue Dungeon" />
        <p>
          HP and Crit Rate sets can be farmed in this dungeon. While the HP set
          is inferior to DEF with the way the Damage Formula works in this game,
          the Crit Rate one is useful on your Attackers.
        </p>
        <h5>Main team</h5>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="rades-spirito" enablePopover />
          <BCMCharacter mode="icon" slug="licht" enablePopover />
          <BCMCharacter mode="icon" slug="rill-boismortier" enablePopover />
          <BCMCharacter
            mode="icon"
            slug="mimosa-vermillion-clover-academy"
            enablePopover
          />
        </div>
        <p>If you don't have all of them, here are some alternatives:</p>
        <ul>
          <li>Licht can be replaced by Fuegoleon Vermillion or Yuno,</li>
          <li>Mimosa (Seasonal) can be replaced by Vanessa.</li>
        </ul>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="fuegoleon-vermillion" enablePopover />
          <BCMCharacter mode="icon" slug="yuno" enablePopover />
          <BCMCharacter mode="icon" slug="vanessa-enoteca" enablePopover />
        </div>
        <h5>Skill setup</h5>
        <p>Coming soon!</p>
        <SectionHeader title="Green Dungeon" />
        <p>
          PEN and Crit DMG sets can be farmed in this dungeon. While PEN set is
          a bit of a niche, the CRIT DMG one is an important one for your
          Attackers (in PVE).
        </p>
        <h5>Main team</h5>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="asta" enablePopover />
          <BCMCharacter mode="icon" slug="yami-sukehiro" enablePopover />
          <BCMCharacter mode="icon" slug="charlotte-roselei" enablePopover />
          <BCMCharacter mode="icon" slug="mimosa-vermillion" enablePopover />
        </div>
        <p>If you don't have all of them, here are some alternatives:</p>
        <ul>
          <li>Mimosa can be replaced by Theresa.</li>
        </ul>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="theresa" enablePopover />
        </div>
        <h5>Skill setup</h5>
        <p>Coming soon!</p>
        <h5>Alternative team</h5>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="noelle-silva" enablePopover />
          <BCMCharacter mode="icon" slug="asta-clover-academy" enablePopover />
          <BCMCharacter mode="icon" slug="finral-roulacase" enablePopover />
          <BCMCharacter mode="icon" slug="mimosa-vermillion" enablePopover />
        </div>
        <p>If you don't have all of them, here are some alternatives:</p>
        <ul>
          <li>Mimosa can be replaced by Theresa.</li>
        </ul>
        <div className="employee-container for-nikke">
          <BCMCharacter mode="icon" slug="theresa" enablePopover />
        </div>
        <h5>Skill setup</h5>
        <p>Coming soon!</p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default BCMGuidesFarmGear;

export const Head: React.FC = () => (
  <Seo
    title="Best teams for farm gear | Black Clover M | Prydwen Institute"
    description="List of Best teams for farm gear in Black Clover Mobile."
    game="bcm"
  />
);
